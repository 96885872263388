import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '1300px',
        margin: '0 auto',

        // background: 'rgb(156,201,214)',
        background: 'linear-gradient(90deg, rgba(156,211,214,.6) 10%, rgba(255,255,255,1) 70%, rgb(233 209 209) 110%)',

        background: 'linear-gradient(90deg, rgba(255, 255, 255, .5) 45%, rgba(233, 209, 209) 110%, rgba(255,255,255,1) 100%)',
        borderRadius: '50px 250px',
        padding: '40px 40px 40px',
        marginBottom: '150px',
        [theme.breakpoints.down('lg')]: {
            maxWidth: '1100px',
        },
        [theme.breakpoints.down('md')]: {
            padding: '100px',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '650px',
            padding: '40px 40px 60px',
            borderRadius: '20px 100px',
            marginBottom: '150px',
        },
        [theme.breakpoints.down('xs')]: {
            borderRadius: '20px 100px',
            marginBottom: '100px',
        },
    },
    profileImage: {
        width: '100%',
        borderRadius: '31px 10px 10px 220px',
        [theme.breakpoints.down('md')]: {
            borderRadius: '31px 10px 10px 165px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '80px',
            borderRadius: '15px 80px 10px 10px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '80px',
            borderRadius: '15px 80px 10px 10px',
        },
    },
    details: {
        padding: '0 50px',
        fontFamily: 'system-ui, math, sans-serif',
        color: '#3a3a42',
        fontWeight: 400,
        textAlign: 'left',
        fontSize: '1.4em',
        lineHeight: 'normal',
        [theme.breakpoints.down('lg')]: {
            padding: '0 75px',
        },
        [theme.breakpoints.down('md')]: {
            padding: '0',
            fontSize: '1.3em',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0 10px',
            fontSize: '1.2em',
        },
    },
    thankYouSpan: {
        [theme.breakpoints.down('xl')]: {
            fontWeight: 500, 
            fontSize: '1.7em',
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: '1.4em',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '1.4em',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.7em',

        },
    }
}));

export default useStyles;